@import "colors.scss";

/* GENERAL */

.bg-red {
    background-color: $red;
}

.bg-grey {
    background-color: $grey;
}

.bg-light {
    background-color: $light;
}

.color-red {
    color: $red;
}

.color-grey {
    color: $grey;
}

.color-light {
    color: $light;
}

/* BOOTSTRAP OVERRIDE */

a,
a:hover,
a:active,
a:visited,
a:focus {
    color: $red;
}

.disabled {
    background-color: $light;
    color: $text-color-light;
}
.disabled :disabled {
    background-color: transparent;
}

input[disabled],
select[disabled] {
    background-color: inherit;
    color: $black;
}

.btn {
    background-color: $white;
}

.btn.btn-primary {
    background-color: $red;
    border: 1px solid $red;
    color: $text-color-red;
}

.bootstrap-dialog.type-primary .modal-header {
    background-color: $red;
    color: $text-color-red;
}

.list-group-item.linked,
.list-group-item.linked:hover,
.list-group.item.linked:focus {
    color: $red;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    background-color: $red;
    border-color: $red;
    color: $text-color-red;
}

a.list-group-item:hover {
    background-color: $light;
    color: $text-color-light;
}

.list-group-item.disabled {
    color: $light;
}

.datepicker table {
    thead,
    tfoot {
        background-color: $red;
        color: $text-color-red;
    }
    
    td:hover,
    tr td.day.active:hover,
    thead tr:first-child th:hover,
    tfoot th.today:hover {
        background-color: $light;
        color: $text-color-light;    
    }
    
    tr td.day.active {
        background-image: none;
        background-color: $red;
        color: $text-color-red;    
    }
    
    .day {
        color: $black;
    }
}

/* NAV BAR */

.navbar {
    background-color: $white;
    border-bottom: 1px solid $red;
}

.navbar-title,
.navbar-title:hover,
.navbar-title:active,
.navbar-title:focus,
.navbar-title:visited {
    color: $red;
}

.navbar.admin {
    background-color: $yellow;

    .btn {
        background-color: $yellow;
    }
}

.top-nav-submenu {
    background-color: $white;
    border: 1px solid $red;

    a,
    a:hover,
    a:active,
    a:focus,
    a:visited {
        color: $red;
        text-decoration: none;
    }

    > div:hover {
        color: $text-color-red;
        background-color: $red;

        a,
        a:hover,
        a:active,
        a:focus,
        a:visited {
            color: $text-color-red;
        }
    }
}

/* TABLES */

.table-hover > tbody > tr:hover > th,
.table-hover > tbody > tr:hover > td {
    background-color: #bfbfbf;
}

.selected,
.selected > th,
.selected > td,
.entry.selected .entry-buttons {
    background-color: #bfbfbf;
}

/* SIDEBAR */

.sidebar-left .sidebar-inlet-bottom {
    color: $grey;
}

.sidebar-left ul li a,
.sidebar-left ul li a:hover,
.sidebar-left ul li a:active {
    color: $red;
}

.sidebar-left ul li div.selected {
    background-color: $red;
}
.sidebar-left ul li div.selected a,
.sidebar-left ul li div.selected a:hover,
.sidebar-left ul li div.selected a:active {
    color: $white;
}

/* OTHER */

.select-counter {
    background-color: $red;
    color: $text-color-red;
}

.cb-menu-entry:hover {
    background-color: $red;
    color: $text-color-red;
}

.sidebar-left {
    background-color: $white;
    color: $text-color-white;
}

.property-title {
    background-color: $light;
    color: $text-color-light;
}

.content-label {
    color: $red;
}

#login .btn {
    background-color: $light;
    color: $text-color-light;
}

.detail {
    .content-cell {
        input:invalid {
            background-color: $red;
            color: $text-color-red;
        }

        .bi-wrapper .inline-search {
            border-bottom: 1px solid $yellow;
        }
    }
}

.bi-message {
    color: $red;
}

/* WOCHENZEITEN */

.week-container table thead th {
    background-color: $grey;
    color: $text-color-grey;
}

.day-title {
    background-color: $red;
    color: $text-color-red;
}

.day-title a,
.day-title a:hover,
.day-title a:active {
    color: $white;
}

.day-summary-row {
    background-color: $light;
    color: $text-color-light;
}

.week-summary-row {
    background-color: $red;
    color: $text-color-red;
}

.year-summary-row {
    background-color: $white;
    color: $text-color-white;
}

.summary-row {
    background-color: $light;
    color: $text-color-light;
}

.bad-time {
    background-color: $orange;
    color: $text-color-orange;
    font-weight: bold;
}